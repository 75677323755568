import { FORM_ID } from 'context/form.context'
import { jsonColorConverter } from './colorConvertor'

const cssTagId = 'widget-theme-tag'

export const addThemeCssTag = () => {
  const cssTag = document.getElementById(cssTagId)

  if (!cssTag) {
    return addCssTag()
  } else {
    return cssTag.sheet
  }
}

const addCssTag = () => {
  const styleEl = document.createElement('style')
  styleEl.setAttribute('id', cssTagId)
  styleEl.setAttribute('type', 'text/css')
  document.head.appendChild(styleEl)
  return styleEl.sheet
}

const applyStylesToStyleSheet = (styleSheet, cssRules) => {
  for (let index = 0; index < cssRules.length; index++) {
    styleSheet.insertRule(cssRules[index], styleSheet.cssRules.length)
  }
}

export const addWidgetTheme = (
  styleSheet,
  buttonJsonColor,
  buttonJsonTextColor,
  textColor,
  backColor,
) => {
  if (!styleSheet) return
  const buttonColor = jsonColorConverter(buttonJsonColor)
  const btnTextColor = jsonColorConverter(buttonJsonTextColor)
  const txtColor = jsonColorConverter(textColor)
  const formBackColor = jsonColorConverter(backColor)

  const rules = [
    // common
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > label > svg {
      fill:${formBackColor}
    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > input:checked ~ label > svg {
      fill: ${buttonColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > input:checked ~ label {
      border-color: ${buttonColor};

    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > label > svg:hover {
      fill: ${buttonColor};

    }`,

    // single step theme
    `#${FORM_ID} .widget-field-theme .widget-singleStep-checkbox-theme > input:checked ~ label {
      background: ${jsonColorConverter(buttonJsonColor, 0.7)};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-choice-theme > input:checked ~ label {
      background: ${jsonColorConverter(buttonJsonColor, 0.7)};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-file-preview > figcaption::after {
      background: ${buttonColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-file-preview > figcaption .widget-singleStep-file-preview-delete {
      background: ${buttonColor};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-nps-theme > label:hover  {
      background-color: ${jsonColorConverter(buttonJsonColor, 0.7)};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-nps-theme > input:checked ~ label  {
      background-color: ${jsonColorConverter(buttonJsonColor, 0.7)};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-textInput-theme {
      // outline-color: ${buttonColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-dropdown-theme {
      outline-color: ${buttonColor};
    }`,
    // multi-step common theme
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox input:checked ~ label .widget-multiStep-checkbox-mark {
      border: 1px solid ${buttonColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox input:checked ~ label  {
      background: ${buttonColor};
      border: 1px solid ${buttonColor};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox  > label:hover {
      background: ${buttonColor};
      border: 1px solid ${buttonColor};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox > label  {
      // background: ${jsonColorConverter(buttonJsonColor, 0.2)};
      border: 1px solid ${jsonColorConverter(buttonJsonColor)};
      color: ${txtColor};

    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice input:checked ~ label .widget-multiStep-choice-mark {
      border: 1px solid ${buttonColor};

    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice input:checked ~ label  {
      background: ${buttonColor};
      border: 1px solid ${buttonColor};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice > label:hover  {
      background: ${buttonColor};
      border: 1px solid ${buttonColor};
      color: ${btnTextColor};

    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice > label  {
      // background: ${jsonColorConverter(buttonJsonColor, 0.2)};
      border: 1px solid ${jsonColorConverter(buttonJsonColor)};
      color: ${txtColor};

    }`,
    `#${FORM_ID} .widget-field-theme .widget-textInput-theme {
      outline-color: ${buttonColor} !important;
      border-color: ${jsonColorConverter(buttonJsonColor, 0.6)} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-date-theme {
      // background: ${jsonColorConverter(buttonJsonColor, 0.001)};
      outline-color: ${buttonColor} !important;
      border-color: ${jsonColorConverter(buttonJsonColor, 0.6)} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-fileWrapper-theme {
      color: ${txtColor} 
      background: ${jsonColorConverter(buttonJsonColor, 0.001)};
      outline-color: ${buttonColor} !important;
      border-color: ${jsonColorConverter(buttonJsonColor, 0.6)} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-dropdown-theme {
      border-color: ${jsonColorConverter(buttonJsonColor)};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-dropdown-theme > option:hover {
      outline-color: ${buttonColor};
      background: ${jsonColorConverter(buttonJsonColor, 0.2)};
    }`,

    `#${FORM_ID} .widget-field-theme .widget-sideWidget-nps-theme > label:hover  {
      border-color: ${jsonColorConverter(buttonJsonColor, 0.5)};
      background: ${jsonColorConverter(buttonJsonColor, 0.5)};
      color: ${btnTextColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-sideWidget-nps-theme > input:checked ~ label  {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      color: ${btnTextColor};
    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-theme {
      border-color: ${jsonColorConverter(buttonJsonColor, 0.001)};
      background: ${jsonColorConverter(buttonJsonColor, 0.001)};
      color: ${buttonColor};
    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-border-theme{
      border-color: ${buttonColor};
      background: ${jsonColorConverter(buttonJsonColor, 0.001)};
      color: ${buttonColor};

    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-full-theme {
      border-color: ${buttonColor};
      background: ${buttonColor};
      color: ${btnTextColor};
    }`,

    //icon
    `#${FORM_ID} .widget-field-theme .icon-theme > svg{
      // fill: ${buttonColor};
      stroke: ${buttonColor};
    }`,
  ]

  applyStylesToStyleSheet(styleSheet, rules)
}
