import { useState, useContext, useEffect, useMemo } from 'preact/hooks'
import { createContext } from 'preact'
import { form } from 'api'
import { flattenErrors } from 'utils/error'
import { IntlProvider } from 'preact-i18n'
import locales from 'i18n/locales'
import LocaleSettings from 'settings/locale.settings'
import { addWidgetTheme, addThemeCssTag } from 'utils/theme-setup'
import setInitialValues from 'utils/form-static-value-generator'
import { isNil } from 'ramda'
import fieldDefaultValue from 'components/form/field-default-value'

const WidgetContext = createContext()

const WidgetProvider = ({ formSlug, widgetProps, ...props }) => {
  const [formData, setFormData] = useState(null)
  const [oncePerUser, setUserRestriction] = useState(false)
  const [isWidgetOpen, openWidget] = useState(false)
  const [isFormSubmitted, setFormIsSubmitted] = useState(false)
  const isWidgetSubmittedPreviously = () => localStorage.getItem(
    `formaloo-chatbot-submit-${formSlug}`,
  )

  const shouldBeSubmittedOnlyOnce = useMemo(() => {
    const { once_per_user } = widgetProps
    if (typeof once_per_user === 'string' && once_per_user === 'true')
      return true
    if (typeof once_per_user === 'boolean' && once_per_user) return true

    return false
  }, [widgetProps])

  const [submittedData, setSubmittedData] = useState({})

  // remove these fields from widget
  const unSupportedFields = [
    'matrix',
    'table',
    'regex',
    'barcode',
    'location',
    'signature',
    'custom_verification',
    'phone_verification',
    // 'email_verification',
    // 'city',
    // 'video',
    // 'product',
  ]

  /* 
    the hidden & variable fields should not be displayed on the form,
    and hidden field value is set with alias by the owner 
  */
  const blackListFields = [...unSupportedFields, 'hidden', 'variable']

  const setupWidgetTheme = (
    buttonColor,
    buttonTextColor,
    textColor,
    backColor,
  ) => {
    const styleSheet = addThemeCssTag()
    addWidgetTheme(
      styleSheet,
      buttonColor,
      buttonTextColor,
      textColor,
      backColor,
    )
  }

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const { data, errors } = await form().getOne(formSlug)
        if (data.form) {
          setFormData(formGeneralDataSelector(data.form))
          setupWidgetTheme(
            data.form.button_color,
            data.form.submit_text_color,
            data.form.text_color,
            data.form.background_color,
          )

          return
        }
        flattenErrors(errors).map(error => console.error(error))
      } catch (errors) {
        flattenErrors(errors).map(error => console.error(error))
      }
    }

    setUserRestriction(shouldBeSubmittedOnlyOnce)

    if (!formSlug) {
      return;
    }
    // show widget to user that has not submitted this form previously
    if (shouldBeSubmittedOnlyOnce && isWidgetSubmittedPreviously()) {
        return;
    } else {
      fetchForm()
      return;
    }

  }, [formSlug, widgetProps])

  const getInitialValuesFromField = (formFields = {}) => {
    const fieldsWithoutDefault = ['formula']

    return Object.keys(formFields).reduce((acc, slug) => {

      const fieldType = formFields[slug]?.sub_type || formFields[slug].type
      const defaultValue = fieldsWithoutDefault.includes(fieldType)
        ? ''
        : isNil(formFields[slug]?.default)
          ? fieldDefaultValue(fieldType)
          : formFields[slug]?.default
      return { ...acc, [slug]: defaultValue }
    }, {})
  }

  const formGeneralDataSelector = data => {
    if (!data) return {}
    return {
      logo: data.logo,
      title: data.title,
      showTitle: data.show_title,
      formSlug: data.slug,
      mainTheme: data.background_color,
      backgroundImage: data.background_image,
      borderColor: data.border_color,
      submitTextColor: data.submit_text_color,
      description: data.description,
      formAddress: data.address,
      contentTheme: data.field_color,
      textTheme: data.text_color,
      hasWatermark: data.has_watermark,
      buttonBackground: data.button_color,
      successMessage: data.success_message,
      submitButtonText: data.button_text,
      formType: data.form_type,
      timeLimit: data.time_limit,
      config: data.config,
      showScore: data.show_calculations_score_result,
      formCurrency: data.currency,
      formCurrencyType: data.form_currency_type,
      themeConfigs: data.theme_config,
      formFields: data.fields_list.filter(
        field => !blackListFields.includes(field.type) && !blackListFields.includes(field.sub_type),
      ),
      fields: data.fields,
      hiddenFields: data.fields_list.filter(field => field.type === 'hidden'),
      variableFields: data.fields_list.filter(field => field.type === 'variable'),
      // generate initial values by alias fields with url params or the fields passed to script as props
      initialValues: getInitialValuesFromField(data.fields),
      logic: data.logic,
      logicMetadata: data.logic_metadata,
      formRedirectsAfterSubmit: data.form_redirects_after_submit,
      shouldSaveDraft: data.accept_draft_answers,

    }
  }



  const closeTheWidget = () => {
    openWidget(false)
  }
  const closeWidget = justClose => {
    if (justClose) {
      closeTheWidget()
      return
    }
    setFormIsSubmitted(true)
    setTimeout(() => {
      openWidget(false)
      // reset submit status
      setFormIsSubmitted(false)
      // if (widgetProps.once_per_user) {
      localStorage.setItem(`formaloo-chatbot-submit-${formSlug}`, true)
      // }
    }, 5000)
  }

  return (
    <IntlProvider definition={locales[LocaleSettings.locale]}>
      <WidgetContext.Provider
        value={{
          formData,
          closeWidget,
          openWidget,
          isWidgetOpen,
          isFormSubmitted,
          setSubmittedData,
          submittedData,
          oncePerUser,
          ...widgetProps,
        }}
        {...props}
      />
    </IntlProvider>
  )
}

const useWidget = () => {
  const context = useContext(WidgetContext)
  if (context === undefined) {
    throw new Error(`useWidget must be used within a WidgetProvider`)
  }
  return context
}
export { WidgetContext, WidgetProvider, useWidget }
