import { FORM_ID } from 'context/form.context'
import { updateAlpha } from './colorConvertor'

const cssTagId = 'widget-theme-tag'

export const addThemeCssTag = () => {
  const cssTag = document.getElementById(cssTagId)

  if (!cssTag) {
    return addCssTag()
  } else {
    return cssTag.sheet
  }
}

const addCssTag = () => {
  const styleEl = document.createElement('style')
  styleEl.setAttribute('id', cssTagId)
  styleEl.setAttribute('type', 'text/css')
  document.head.appendChild(styleEl)
  return styleEl.sheet
}

const applyStylesToStyleSheet = (styleSheet, cssRules) => {
  for (let index = 0; index < cssRules.length; index++) {
    styleSheet.insertRule(cssRules[index], styleSheet.cssRules.length)
  }
}



export const addWidgetTheme = (styleSheet, generateThemeConfigs) => {
  if (!styleSheet) return

  const {
    btnBackgroundColor,
    btnTextColor,
    txtColor,
    formBackgroundColor,
    field_color_light,
    field_color_light_hover,
    field_color,
    border_color,
    border_color_hover,
    fieldRadius,
    buttonRadius,
  } = generateThemeConfigs()

  const rules = [
    // common
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > label > svg {
      stroke:${field_color} !important;
      fill:${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > input:checked ~ label > svg {
      fill: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > input:checked ~ label {
      border-color: ${field_color} !important;

    }`,
    `#${FORM_ID} .widget-field-theme .widget-likeDislike-theme > label > svg:hover {
      fill: ${field_color} !important;

    }`,

    // single step theme
    `#${FORM_ID} .widget-field-theme .widget-singleStep-checkbox-theme > input:checked ~ label {
      background: ${updateAlpha(field_color, 0.7)} !important;
      color: ${txtColor} !important;
       border-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-choice-theme > input:checked ~ label {
      background: ${updateAlpha(field_color, 0.7)} !important;
      color: ${txtColor} !important;
       border-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-file-preview > figcaption::after {
      background: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-file-preview > figcaption .widget-singleStep-file-preview-delete {
      background: ${field_color} !important;
      color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-nps-theme > label:hover  {
      background-color: ${updateAlpha(field_color, 0.7)} !important;
      color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-nps-theme > input:checked ~ label  {
      background-color: ${updateAlpha(field_color, 0.7)} !important;
      color: ${txtColor};
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-textInput-theme {
      // outline-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-singleStep-dropdown-theme {
      outline-color: ${field_color} !important;
    }`,
    // multi-step common theme
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox {
      background-color: ${field_color_light} !important;
      border: 1px solid ${field_color} !important;
      border-radius: ${fieldRadius} !important;
       border-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox-checked {
      background-color: ${field_color_light_hover} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox > input {
      border: 2px solid ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox-check-mark {
      border: 2px solid ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox > input:checked {
      background-color: ${field_color} !important;
      color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox:hover {
      background-color: ${field_color_light_hover} !important; 
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-checkbox > label  {
      color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice  {
      background-color: ${field_color_light} !important;
      border: 1px solid ${field_color} !important;
      border-radius: ${fieldRadius} !important;
      border-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice:hover{
      background-color: ${field_color_light_hover} !important;
      border: 1px solid ${field_color} !important;
      color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-multiStep-choice > label  {
      color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-textInput-theme {
      background: ${field_color_light} !important;
      border: 1px solid ${border_color};
      outline-color: ${border_color} !important;
      border-color: ${border_color} !important;
      border-radius: ${fieldRadius} !important
    }`,
    `#${FORM_ID} .widget-field-theme .widget-date-theme {
      // background: ${updateAlpha(field_color, 0.001)} !important;
      outline-color: ${field_color} !important;
      border-color: ${updateAlpha(field_color, 0.6)} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-product-theme {
      color: ${txtColor}  !important
      background: ${field_color_light} !important;
      outline-color: ${field_color} !important;
      border-color: ${field_color} !important;
      border-radius: ${fieldRadius} !important
    }`,
    `#${FORM_ID} .widget-field-theme .widget-fileWrapper-theme {
      color: ${txtColor}  !important
      background: ${field_color_light} !important;
      outline-color: ${field_color} !important;
      border-color: ${field_color} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .widget-dropdown-theme {
      background: ${field_color_light} !important;
      border: 1px solid ${border_color} !important;
      border-radius: ${fieldRadius} !important

    }`,
    `#${FORM_ID} .widget-field-theme .widget-dropdown-theme > option:hover {
      outline-color: ${field_color} !important;
      background: ${updateAlpha(field_color, 0.2)} !important;
    }`,

    `#${FORM_ID} .widget-field-theme .widget-sideWidget-nps-theme > input ~ label  {
      background-color: transparent !important;
      border: 1px solid ${field_color} !important;
      color: ${field_color} !important;
      
    }`,
    `#${FORM_ID} .widget-field-theme .widget-sideWidget-nps-theme > input:checked ~ label  {
      background-color: ${field_color} !important;
      border-color: ${field_color} !important;
      color: ${txtColor} !important;
    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-contained {
      color: ${btnTextColor} !important;
      background-color: ${btnBackgroundColor} !important;
      border: 1px solid ${btnBackgroundColor} !important;
      border-radius: ${buttonRadius} !important
    }`,
    `#${FORM_ID} .widget-field-theme .button-text {
      color: ${btnBackgroundColor} !important;
      background-color: transparent !important;
      border:none
    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-outlined{
      color: ${btnBackgroundColor} !important;
      background: white !important;
      border: 1px solid ${btnBackgroundColor} !important;
      border-radius: ${buttonRadius} !important

    }`,

    //buttonTheme
    `#${FORM_ID} .widget-field-theme .button-full-theme {
      color: ${txtColor} !important;
      background-color: ${field_color} !important;
      border: 1px solid ${border_color} !important;
      border-radius: ${buttonRadius} !important;
    }`,

    //icon
    `#${FORM_ID} .widget-field-theme .icon-theme > svg{
      // fill: ${field_color} !important;
      stroke: ${field_color} !important;
    }`,

    `#${FORM_ID} .widget-field-theme .top-bar{
    background-color: ${field_color} !important;
    color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .text-color{
    color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .button-text-color{
    color: ${txtColor} !important;
    }`,
    `#${FORM_ID} .widget-field-theme .button-light-background-color{
    background: ${field_color_light} !important;
    }`,
  ]

  applyStylesToStyleSheet(styleSheet, rules)
}
