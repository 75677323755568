import { WidgetProvider } from 'context/widget.context'
import { Provider } from 'react-redux'
import store from 'stores/store'
import WidgetMapper from './widget-mapper'

export default function App({ type, slug, ...props }) {
  return (
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, ff-mark-pro, sans-serif',
      }}
    >
      <WidgetProvider
        formSlug={slug}
        widgetProps={{ ...props, widgetType: type }}
      >
        <Provider store={store}>
          <WidgetMapper widgetType={type} />
        </Provider>
      </WidgetProvider>
    </div>
  )
}
