import { useState, useContext, useEffect, useMemo } from 'preact/hooks'
import { createContext } from 'preact'
import { form } from 'api'
import { flattenErrors } from 'utils/error'
import { IntlProvider } from 'preact-i18n'
import locales from 'i18n/locales'
import LocaleSettings from 'settings/locale.settings'
import { addWidgetTheme, addThemeCssTag } from 'utils/theme-setup'
import { isEmpty, isNil } from 'ramda'
import fieldDefaultValue from 'components/form/field-default-value'
import {
  convertColorToRGBA,
  hexToRGBA,
  jsonColorConverter,
} from 'utils/colorConvertor'

const WidgetContext = createContext()

const WidgetProvider = ({ formSlug, widgetProps, ...props }) => {
  const [formData, setFormData] = useState(null)
  const [oncePerUser, setUserRestriction] = useState(false)
  const [isRtl, setIsRtl] = useState(false)
  const [isWidgetOpen, openTheWidget] = useState(false)
  const [isFormSubmitted, setFormIsSubmitted] = useState(false)
  const isWidgetSubmittedPreviously = () =>
    localStorage.getItem(`formaloo-chatbot-submit-${formSlug}`)

  const shouldBeSubmittedOnlyOnce = useMemo(() => {
    const { once_per_user } = widgetProps
    if (typeof once_per_user === 'string' && once_per_user === 'true')
      return true
    if (typeof once_per_user === 'boolean' && once_per_user) return true

    return false
  }, [widgetProps])

  const [submittedData, setSubmittedData] = useState({})

  // remove these fields from widget
  const unSupportedFields = [
    'matrix',
    'table',
    'regex',
    'barcode',
    'location',
    'signature',
    'custom_verification',
    'phone_verification',
    'linked_rows',
    'repeating_section',
    'lookup',
    'oembed',
  ]

  /* 
    the hidden & variable fields should not be displayed on the form,
    and hidden field value is set with alias by the owner 
  */
  const blackListFields = [...unSupportedFields, 'hidden', 'variable']

  const generateThemeConfigs = () => {
    const generateRGBA = (color, alpha, defaultColor) =>
      color ? convertColorToRGBA(color, alpha) : defaultColor

    const getColor = (color, defaultColor) =>
      color ? jsonColorConverter(color) : defaultColor

    const radiusMap = { small: '1px', normal: '6px', large: '12px' }

    const defaultConfig = {
      btnBackgroundColor: '#006AFF',
      btnTextColor: '#FFFFFF',
      txtColor: '#272E35',
      formBackgroundColor: '#FFFFFF',
      field_color_light: hexToRGBA('#555F6D', 5),
      field_color_light_hover: hexToRGBA('#555F6D', 20),
      field_color: hexToRGBA('#555F6D', 100),
      border_color: hexToRGBA('#555F6D', 50),
      border_color_hover: hexToRGBA('#555F6D', 100),
      fieldRadius: '6px',
      buttonRadius: '6px',
    }

    const themeRadius = formData?.themeConfigs?.radius || 'normal'
    const fieldColor = formData?.contentTheme
    const borderColor = formData?.borderColor

    return {
      btnBackgroundColor: getColor(
        formData?.buttonBackground,
        defaultConfig.btnBackgroundColor,
      ),
      btnTextColor: getColor(
        formData?.submitTextColor,
        defaultConfig.btnTextColor,
      ),
      txtColor: getColor(formData?.textTheme, defaultConfig.txtColor),
      formBackgroundColor: getColor(
        formData?.mainTheme,
        defaultConfig.formBackgroundColor,
      ),
      field_color_light: generateRGBA(
        fieldColor,
        5,
        defaultConfig.field_color_light,
      ),
      field_color_light_hover: generateRGBA(
        fieldColor,
        20,
        defaultConfig.field_color_light_hover,
      ),
      field_color: generateRGBA(fieldColor, 100, defaultConfig.field_color),
      border_color: generateRGBA(borderColor, 50, defaultConfig.border_color),
      border_color_hover: generateRGBA(
        borderColor,
        100,
        defaultConfig.border_color_hover,
      ),
      fieldRadius: radiusMap[themeRadius],
      buttonRadius: themeRadius === 'large' ? '100px' : radiusMap[themeRadius],
    }
  }

  const setupWidgetTheme = () => {
    const styleSheet = addThemeCssTag()
    addWidgetTheme(styleSheet, generateThemeConfigs)
  }

  useEffect(() => {
    if (formData) {
      setupWidgetTheme()
    }
  }, [formData])
  useEffect(() => {
    const dir = formData?.direction
    if (dir) {
      setIsRtl(dir === 'rtl')
    }
  }, [formData?.direction])

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const { data, errors } = await form().getOne(formSlug)

        if (data.form) {
          setFormData(formGeneralDataSelector(data.form))
          return
        }
        flattenErrors(errors).map(error => console.error(error))
      } catch (errors) {
        flattenErrors(errors).map(error => console.error(error))
      }
    }

    setUserRestriction(shouldBeSubmittedOnlyOnce)

    if (!formSlug) {
      return
    }
    // show widget to user that has not submitted this form previously
    if (shouldBeSubmittedOnlyOnce && isWidgetSubmittedPreviously()) {
      return
    } else {
      fetchForm()
      return
    }
  }, [formSlug, widgetProps])

  const getInitialValuesFromField = (formFields = {}) => {
    const fieldsWithoutDefault = ['formula']

    return Object.keys(formFields).reduce((acc, slug) => {
      const fieldType = formFields[slug]?.sub_type || formFields[slug].type
      const defaultValue = fieldsWithoutDefault.includes(fieldType)
        ? ''
        : isNil(formFields[slug]?.default)
        ? fieldDefaultValue(fieldType)
        : formFields[slug]?.default
      return { ...acc, [slug]: defaultValue }
    }, {})
  }

  const formGeneralDataSelector = data => {
    if (!data) return {}

    return {
      logo: data.logo_url,
      title: data.title,
      showTitle: data.show_title,
      formSlug: data.slug,
      mainTheme: data.background_color,
      backgroundImage: data.background_image_url,
      themeBackgroundImage: data.theme_config?.theme_background_pattern,
      backgroundImageBrightness:
        data.theme_config?.background_image?.background_image_brightness,
      focalPoint: data.theme_config?.background_image?.focal_point,
      borderColor: data.border_color,
      submitTextColor: data.submit_text_color,
      description: data.description,
      formAddress: data.address,
      contentTheme: data.field_color,
      textTheme: data.text_color,
      hasWatermark: data.has_watermark,
      buttonBackground: data.button_color,
      successMessage: data.success_message,
      submitButtonText: data.button_text,
      formType: data.form_type,
      timeLimit: data.time_limit,
      config: data.config,
      showScore: data.show_calculations_score_result,
      formCurrency: data.currency,
      formCurrencyType: data.form_currency_type,
      themeConfigs: data.theme_config,
      formFields: data.fields_list.filter(
        field =>
          !blackListFields.includes(field.type) &&
          !blackListFields.includes(field.sub_type),
      ),
      fields: data.fields,
      hiddenFields:
        data.fields_list.filter(field => field.type === 'hidden') ?? [],
      variableFields: data.fields_list.filter(
        field => field.type === 'variable',
      ),
      // generate initial values by alias fields with url params or the fields passed to script as props
      initialValues: getInitialValuesFromField(data.fields),
      logic: data.logic,
      logicMetadata: data.logic_metadata,
      formRedirectsAfterSubmit: data.form_redirects_after_submit,
      shouldSaveDraft: data.accept_draft_answers,
      combinedLocalizedContent: data.combined_localized_content,
      direction: data.language?.direction ?? 'ltr',
    }
  }

  const openWidget = () => {
    openTheWidget(true)
  }

  const closeWidget = () => {
    openTheWidget(false)
    // reset submit status
    setFormIsSubmitted(false)
    // if (widgetProps.once_per_user) {
    localStorage.setItem(`formaloo-chatbot-submit-${formSlug}`, true)
    // }
  }

  const displayEndingPages = () => {
    setFormIsSubmitted(true)
  }

  return (
    <IntlProvider definition={locales[LocaleSettings.locale]}>
      <WidgetContext.Provider
        value={{
          formData,
          closeWidget,
          displayEndingPages,
          openWidget,
          isWidgetOpen,
          isFormSubmitted,
          setSubmittedData,
          submittedData,
          oncePerUser,
          generateThemeConfigs,
          isRtl,
          ...widgetProps,
        }}
        {...props}
      />
    </IntlProvider>
  )
}

const useWidget = () => {
  const context = useContext(WidgetContext)
  if (context === undefined) {
    throw new Error(`useWidget must be used within a WidgetProvider`)
  }
  return context
}
export { WidgetContext, WidgetProvider, useWidget }
