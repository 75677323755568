/**
 * Convert a JSON color to RGBA format with optional custom opacity 
 * or return the default color in case of an exception
 *
 * @param {string} jsonColor - The JSON color to be converted
 * @param {number} customOpacity - Optional custom opacity value
 * @param {string} defaultColor - The default color to return in case of an exception
 * @return {string} The RGBA color string or the default color in case of an exception
 */
export function jsonColorConverter(jsonColor, customOpacity, defaultColor = 'rgba(0, 0, 0, 1)') {
  try {
    const colorObject = JSON.parse(jsonColor);
    const opacity = customOpacity ?? colorObject.a;
    return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, ${opacity})`;
  } catch (error) {
    return defaultColor ?? defaultColor;
  }
}